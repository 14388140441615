@font-face {
  font-family: "72";
  src: url("../../node_modules/@sap-theming/theming-base-content/content/Base/baseLib/sap_fiori_3/preview.less") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SAP-icons";
  src: url("../../node_modules/@sap-theming/theming-base-content/content/Base/baseLib/sap_fiori_3/fonts/SAP-icons.woff2") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
}

.btn-group {
  white-space: nowrap;
}
.btn-group, .list-button {
  margin: 0.125rem !important;
  display: inline-block;
}

.err-span{
  color: #BB0000;
}

.action-button{
  display: inline-block !important;
}

#file-reader{
  margin: 0.125rem !important;
  display: inline-block;
  float: right;
}

#label-img-div{
  display:table-cell;
  vertical-align:middle;
  margin:auto;
}

#label-image{
  width: 100%;
  max-width: 230px;
  height: 100%;
  max-height: 230px;
}

.custom-table{
  overflow: auto;
  height: 75vh;
}

.curating-grid{
  overflow: auto;
}

.action-buttons {
  margin-top: 1.5rem;
}
.fd-layout-panel__actions .fd-button, button.fd-button {
  margin-right: .250rem;
}

.fd-layout-panel__body{
  white-space: nowrap;
}

.fd-layout-panel__body .inline-form{
  display: inline-flex;
  width: 33.3%;
  margin: 0.02rem;
}

.custom-dialog{
  z-index: 1;
}
